body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e2b4d1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #8a1b61; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #4e0734; 
}

.list-container td>span {
  max-height: 200px;
  display: inline-block;
  overflow: auto;
}